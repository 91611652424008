<template>
  <router-view />
</template>
<script>
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import settingsDispatchModule from '@/store/settings/fulfillment/dispatch'
import config from './config'

export default {
  name: 'Dispatch',
  created() {
    this.$emit('updateMenu', 'settings-fulfillment-dispatch')
  },
  setup() {
    const { MODULE_NAME } = config()

    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, settingsDispatchModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })

    return {
      MODULE_NAME,
    }
  },
}
</script>
