import axios from '@axios'
import crudActions from '@/libs/storeConstActions'
import { customActionGet } from '@core/utils/utils'
import { stringify } from 'qs'

const endpoint = 'settings/fulfillment/dispatches'
const Actions = crudActions(endpoint)

const restoreFromTrash = (ctx, data) => axios.post(`/${endpoint}/${data}/change-status`, { dispatch: data, is_active: 'true' })
const moveToTrash = (ctx, data) => axios.post(`/${endpoint}/${data}/change-status`, { dispatch: data, is_active: 'false' })
const duplicate = (ctx, data) => axios.post(`/${endpoint}/${data}/duplicate`, { dispatch: data })
const setPosition = (ctx, { dispatch, position }) => axios.post(`/${endpoint}/${dispatch}/set-position/${position}`, { dispatch, position })

const setDefault = (ctx, data) => axios.post(`/${endpoint}/set-default`, { id: data })

const suppliersEndpoint = () => 'customers'

const suppliers = (_, queryParams) => customActionGet(suppliersEndpoint(), queryParams, () => {}, '')
const getSuppliers = ({ commit }, queryParams) => new Promise((resolve, reject) => {
  axios.get(suppliersEndpoint(), {
    params: queryParams,
    paramsSerializer: params => stringify(params),
  })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
const getCustomer = ({ }, { id, queryParams = {} }) => customActionGet(`customers/${id}`, queryParams)

export default {
  ...Actions,
  suppliers,
  getSuppliers,
  restoreFromTrash,
  moveToTrash,
  duplicate,
  setPosition,
  getCustomer,
  setDefault,
}
