export default {
  GET(state, data) {
    state.dispatch = { ...data }
  },
  LIST(state, data) {
    state.dispatchList = data
  },
  SET_FORM(state, data) {
    state.dispatchForm = data
  },
  SET_CUSTOMER_INFORMATION_FORM(state, data) {
    state.customerInformationForm = { ...data }
  },
}
